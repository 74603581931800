@use "settings/settings" as *;

/* stylelint-disable selector-max-type, selector-max-universal */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  /* Needed to avoid 300ms lag on click on mobile devices */
  touch-action: manipulation;
}

body {
  background-color: var(--color-bg-mid);
  font-family: $font-family-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.3;
  margin: 0;

  /* Disables pull-to-refresh but allows overscroll glow effects. */
  overscroll-behavior-y: contain;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  @include overflow-x-clip-when-supported();
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* Normalize all font-size to the body text size. */
  font-size: 1rem;
  font-weight: $font-weight-normal;
  margin-bottom: 0;
  margin-top: 0;
}

p {
  margin-bottom: 0;
  margin-top: 0;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

::placeholder {
  color: var(--color-placeholder-text);
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}

input,
textarea,
select,
button {
  font-family: inherit;

  &:focus::placeholder {
    opacity: 0.6;
  }
}

figure {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
