// This replaces the built-in Videogular icons with Google Material Icons

/* stylelint-disable */
[class^="vg-icon-"],
[class*=" vg-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Material Icons" !important;
}

.vg-icon-closed_caption:before {
  content: "\e01c";
}
.vg-icon-pause:before {
  content: "\e034";
}
.vg-icon-play_arrow:before {
  content: "\e037";
}
.vg-icon-repeat:before {
  content: "\e040";
}
.vg-icon-replay:before {
  content: "\e042";
}
.vg-icon-skip_next:before {
  content: "\e044";
}
.vg-icon-skip_previous:before {
  content: "\e045";
}
.vg-icon-stop:before {
  content: "\e047";
}
.vg-icon-volume_down:before {
  content: "\e04d";
}
.vg-icon-volume_mute:before {
  content: "\e04e";
}
.vg-icon-volume_off:before {
  content: "\e04f";
}
.vg-icon-volume_up:before {
  content: "\e050";
}
.vg-icon-hd:before {
  content: "\e052";
}
.vg-icon-forward_10:before {
  content: "\e056";
}
.vg-icon-forward_30:before {
  content: "\e057";
}
.vg-icon-replay_10:before {
  content: "\e059";
}
.vg-icon-replay_30:before {
  content: "\e05a";
}
.vg-icon-fullscreen:before {
  content: "\e5d0";
}
.vg-icon-fullscreen_exit:before {
  content: "\e5d1";
}

vg-player {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Material Icons" !important;
}
/* stylelint-enable */
