@use "settings/settings" as *;

/**
 *  ReadMore global styles
 */
.ReadMoreButton {
  background: none;
  border: 0;
  color: var(--color-link);
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  padding: 0;
  position: relative;
  text-align: left;
  transition: color 0.1s ease-out;

  &[data-link-color="light"] {
    color: $link-color-light;
    text-decoration: underline;
  }

  &:hover {
    color: var(--color-link-hover);

    &[data-link-color="light"] {
      color: $link-color-light;
      text-decoration: none;
    }
  }

  &::before {
    content: "";
    display: block;
    height: 22px;
    left: -4px;
    padding: 0 $spacing-small;
    position: absolute;
    top: -3px;
    width: 44px;
  }
}
