@use "@angular/material" as mat;
@use "@angular/cdk" as cdk;

$fln-app-primary: mat.define-palette(mat.$blue-palette);
$fln-app-accent: mat.define-palette(mat.$pink-palette);
$fln-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $fln-app-primary,
      accent: $fln-app-accent,
    ),
  )
);

/**
 * Material base styling
 * This is where mat.core() would normally be included, but we just include
 * relevant mixins and functions to reduce the size of the final CSS bundle.
 */
@include mat.ripple();
@include cdk.a11y-visually-hidden();
@include cdk.overlay();
@include cdk.text-field();

/**
 * Apply theme to components
 * Typography needs to be applied separately here since it's not defined in the theme
 */
@include mat.button-typography(mat.define-typography-config());
@include mat.datepicker-typography(mat.define-typography-config());
@include mat.button-theme($fln-app-theme);
@include mat.datepicker-theme($fln-app-theme);
