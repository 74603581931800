/* stylelint-disable */
@use "settings/settings" as *;

/* Overlays and portals in Material2 needs some global styling for the
  overlay and portal containers.
  https://github.com/angular/material2/blob/master/src/cdk/overlay/_overlay.scss
  TODO: Remove these to a component as these are for Material2
  NOTE: We don't use the z-index from Material2 because the use of
        overlays follow our z-index and @angular/cdk/overlay doesn't
        support editing z-index yet, so we'll set z-index on the
        components that gets used as a ComponentPortal.
        Ref: https://github.com/angular/material2/issues/1432 */
.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  /*
    - The container should be the size of the viewport.
    - Disable events from being captured on the overlay container.
  */

  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9001;
}

/* We use an extra wrapper element in order to use make the overlay itself a flex item.
 This makes centering the overlay easy without running into the subpixel rendering
 problems tied to using `transform` and without interfering with the other position
 strategies. */
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  // z-index: 9001;
}

/* This is for mat-datepicker */
.cdk-overlay-connected-position-bounding-box {
  z-index: $z-index-modal;
}

/* mat-datepicker is meant to be used with material theme
   but we're using it standalone which causes some weird styling issues */
.mat-calendar-body-cell:focus .mat-focus-indicator::before {
  margin: 0;
} 

/* Select only the first one (i.e. start-date's) as end-date is also wrapped with the
 same wrapper class. */
.mat-date-range-input-wrapper .mat-date-range-input-inner:first-child {
  position: static !important; // `!important` to override inline styles applied by lib
}

.mat-date-range-input-mirror {
  display: none !important; // `!important` to override inline styles applied by lib
}

/* The overlay-container is an invisible element which contains all
  individual overlays. */
// .cdk-overlay-container {
//   position: fixed;
//   z-index: 9001;
// }

/*
 * Overwrite mat-datepicker theme values with our dark/light mode theming
 * FIXME T301214: Create a proper dark theme in fl-material-theme.scss instead.
*/
html {
  --mat-datepicker-calendar-container-background-color: var(--color-bg-light);
  --mat-datepicker-calendar-body-label-text-color: var(--color-foreground);
  --mat-datepicker-calendar-container-text-color: var(--color-foreground);
  --mat-datepicker-calendar-date-text-color: var(--color-foreground);
  --mat-datepicker-calendar-date-disabled-state-text-color: var(--color-hover-light);
  --mat-datepicker-calendar-header-text-color: var(--color-foreground);
  --mat-datepicker-calendar-navigation-button-icon-color: var(--color-foreground);
  --mat-datepicker-calendar-period-button-icon-color: var(--color-foreground);
  --mat-datepicker-toggle-icon-color: var(--color-foreground);
  --mat-datepicker-calendar-header-divider-color: var(--color-border-light);
  --mat-datepicker-calendar-date-today-outline-color: var(--color-border-light);
}

// When unthemed, this var is defined in the css for the element, rather than globally.
// We need to override it at that location to apply the css
// Note: needs this many selectors to win out on specificity.
.mat-calendar-period-button.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: var(--color-foreground);
}

.cdk-overlay-backdrop {
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: auto;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  z-index: $z-index-modal-background;

  &.cdk-overlay-backdrop-showing {
    opacity: 0.48;
  }
}

.cdk-overlay-dark-backdrop {
  background: $overlay-dark;
}

.cdk-overlay-transparent-backdrop {
  background: none;
}

/* A single overlay pane. */
.cdk-overlay-pane {
  box-sizing: border-box;
  pointer-events: auto;
  position: fixed;
  z-index: 9001;
}

/* Used when disabling global scrolling. */
.cdk-global-scrollblock {
  // Note: this will always add a scrollbar to whatever element it is on, which can
  // potentially result in double scrollbars. It shouldn't be an issue, because we won't
  // block scrolling on a page that doesn't have a scrollbar in the first place.
  overflow-y: scroll;

  position: fixed;

  // Necessary for the content not to lose its width. Note that we're using 100%, instead of
  // 100vw, because 100vw includes the width plus the scrollbar, whereas 100% is the width
  // that the element had before we made it `fixed`.
  width: 100%;
}

/* Puts the google places autocomplete dropdown results z-index
  higher than modals and callouts */
.pac-container {
  z-index: $z-index-callout + 1;
}

// Custom styling for the material tooltip.
.cdk-overlay-container {
  .mdc-tooltip__surface {
    @include fontDetail();
    // Apply material theme
    --mdc-plain-tooltip-container-color: #{$tooltip-background};
    --mdc-plain-tooltip-container-shape: #{$border-radius-small};
    --mdc-plain-tooltip-supporting-text-color: #{$color-light};

    box-shadow: $box-shadow-level-1;
    padding: $spacing-xxsmall $spacing-xsmall;
  }
}

/* Google Maps library overrides */
.gm-style .gm-style-iw {
  overflow: visible;
}

.gm-style .gm-style-iw-c {
  padding: 0px; // remove Info Window padding
  overflow: visible; // to enable Info Window area extension for mouse events
}

.gm-style .gm-style-iw-d {
  overflow: visible !important; // remove Info Window padding - !important due to inline styles
}

.gm-style .gm-style-iw-tc {
  pointer-events: none; // prevent Google Maps' Info Window arrow from blocking mouse events
}

/* stylelint-enable */
