@use "breakpoints" as *;
@use "colors-generic" as *;
@use "colors-implementation" as *;
@use "fonts" as *;
@use "typography" as *;
@use "variables" as *;

/*
  This mixin adds ellipsis to the end of a single line of text
  when there's not enough space.
 */
@mixin ellipsis() {
  @include overflow-clip-when-supported();
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*
  To be used with ::before and ::after pseudo elements
  These rules are always needed on those.
 */
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

/*
  Triangle mixin. It generates a triangle in css
 */
@mixin triangle($color, $direction, $size: 6px, $position: absolute) {
  @include pseudo($pos: $position);
  border-radius: 3px;
  height: 0;
  width: 0;

  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  }

  @else if $direction == up {
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
  }

  @else if $direction == right {
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    border-top: $size solid transparent;
  }

  @else if $direction == left {
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    border-top: $size solid transparent;
  }
}

/*
  Vertically align the element with `transform` and absolute positioning.
 */
@mixin vertical-align($pos: absolute, $translateY: -50%) {
  position: $pos;
  top: 50%;
  transform: translateY($translateY);
}

/*
  Horizontally align the element with `transform` and absolute positioning.
 */
@mixin horizontal-align($pos: absolute) {
  left: 50%;
  position: $pos;
  transform: translateX(-50%);
}

/*
  Horizontally and Vertically  align the element with `transform` and absolute positioning.
*/
@mixin horizontal-vertical-align($pos: absolute) {
  left: 50%;
  position: $pos;
  top: 50%;
  transform: translate(-50%, -50%);
}

/*
  Sets the element so that it cover the parent element entirely
*/
@mixin absolute-cover() {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin visibly-hidden() {
  @include overflow-clip-when-supported();
  height: 1px;
  left: -10000px;
  position: absolute;
  top: auto;
  width: 1px;
}

@mixin word-break() {
  word-break: break-word; /* old webkit */
  word-wrap: break-word; /* webkit */
}

@mixin text-size-generator($name: "") {
  @if $name != "" {
    $name: "-" + $name;
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=xxxsmall]"} {
    @include fontTiny();
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=xxsmall]"} {
    @include fontDetail();
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=xsmall]"} {
    @include fontUi();
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=small]"} {
    @include fontLanding();
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=marketing_small]"} {
    @include fontHeading4();
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=mid]"} {
    @include fontHeading3();
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=large]"} {
    @include fontHeading2();
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=xlarge]"} {
    @include fontHeading1();
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=xxlarge]"} {
    @include fontHeroCompact();
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=xxxlarge]"} {
    @include fontHero();
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=marketing_mega]"} {
    @include fontMegaHero();
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=marketing_xmega]"} {
    @include fontXMegaHero();
  }

  #{if(&, "&", "*")}#{"[data-size" + $name + "=inherit]"} {
    font-size: inherit;
    line-height: inherit;
  }
}

@mixin text-weight-generator($name: "") {
  @if $name != "" {
    $name: "-" + $name;
  }

  #{if(&, "&", "*")}#{"[data-weight" + $name + "=black]"} {
    font-weight: $font-weight-black;
  }

  #{if(&, "&", "*")}#{"[data-weight" + $name + "=bold]"} {
    font-weight: $font-weight-bold;
  }

  #{if(&, "&", "*")}#{"[data-weight" + $name + "=medium]"} {
    font-weight: $font-weight-medium;
  }

  #{if(&, "&", "*")}#{"[data-weight" + $name + "=normal]"} {
    font-weight: $font-weight-normal;
  }

  #{if(&, "&", "*")}#{"[data-weight" + $name + "=light]"} {
    font-weight: $font-weight-light;
  }

  #{if(&, "&", "*")}#{"[data-weight" + $name + "=inherit]"} {
    font-weight: inherit;
  }
}

@mixin text-transform-generator() {
  [data-text-transform="lowercase"] {
    text-transform: lowercase;
  }

  [data-text-transform="capitalize"] {
    text-transform: capitalize;
  }

  [data-text-transform="uppercase"] {
    text-transform: uppercase;
  }
}

@mixin color-generator($size: "") {
  @if $size != "" {
    $size: "-" + $size;
  }

  #{if(&, "&", "*")}#{"[data-color" + $size + "=inherit]"} {
    color: inherit;
  }

  #{if(&, "&", "*")}#{"[data-color" + $size + "=foreground]"} {
    color: var(--color-foreground);
  }

  #{if(&, "&", "*")}#{"[data-color" + $size + "=light]"} {
    color: var(--color-light);
  }

  #{if(&, "&", "*")}#{"[data-color" + $size + "=dark]"} {
    color: var(--color-dark);
  }

  #{if(&, "&", "*")}#{"[data-color" + $size + "=error]"} {
    color: var(--color-error);
  }

  #{if(&, "&", "*")}#{"[data-color" + $size + "=warning]"} {
    color: var(--color-warning);
  }

  #{if(&, "&", "*")}#{"[data-color" + $size + "=success]"} {
    color: var(--color-success);
  }

  #{if(&, "&", "*")}#{"[data-color" + $size + "=primary]"} {
    color: var(--color-primary);
  }
}

@mixin text-decoration-generator() {
  [data-text-decoration="underline"] {
    text-decoration: underline;
  }

  [data-text-decoration="overline"] {
    text-decoration: overline;
  }

  [data-text-decoration="line-through"] {
    text-decoration: line-through;
  }
}

@mixin text-align-generator($name: "") {
  @if $name != "" {
    $name: "-" + $name;
  }

  #{if(&, "&", "*")}#{"[data-text-align" + $name + "=center]"} {
    text-align: center;
  }

  #{if(&, "&", "*")}#{"[data-text-align" + $name + "=left]"} {
    text-align: left;
  }

  #{if(&, "&", "*")}#{"[data-text-align" + $name + "=right]"} {
    text-align: right;
  }
}

/**
* Background Ripple:
* - Adds base background
* - Changes background on :hover
* - Creates ripple effect on :active state (click)
* - Setting the defaultColor to 'transparent' will
*   change the animation to flashing the activeColor every click
*/
@mixin background-ripple($defaultColor, $hoverColor, $activeColor) {
  background: $defaultColor;
  background-position: center;

  &:hover,
  &:focus-within {
    background: $hoverColor
      radial-gradient(circle, transparent 1%, $hoverColor 1%) center/15000%;
    transition: background 0.5s, border-color 0.5s;

    @media (hover: none) {
      background: $defaultColor
        radial-gradient(circle, transparent 1%, $defaultColor 1%) center/15000%;
    }
  }

  &:active {
    background-color: $activeColor;
    background-size: 100%;
    transition: background 0s;
  }
}

@mixin radial-gradient($colorFrom, $colorTo) {
  background: $colorTo radial-gradient(circle, $colorFrom, $colorTo);
}

/*
  - Notch support to keep elements in the safe zone.
  - Used to disable stylelint only in one place.
*/

/* stylelint-disable */
@mixin notch-styles($top: false) {
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  @if $top {
    @supports (padding: max(0px)) {
      padding-top: max(#{$top}, env(safe-area-inset-top));
    }
  }
  @else {
    padding-top: env(safe-area-inset-top);
  }
}

@mixin notch-styles-sides($horizontalPadding: false) {
  @if $horizontalPadding {
    @supports (padding: max(0px)) {
      padding-left: max(#{$horizontalPadding}, env(safe-area-inset-left));
      padding-right: max(#{$horizontalPadding}, env(safe-area-inset-right));
    }
  }
  @else {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

@mixin notch-styles-top($top: false) {
  @if $top {
    @supports (padding: max(0px)) {
      padding-top: max(#{$top}, env(safe-area-inset-top));
    }
  }
  @else {
    padding-top: env(safe-area-inset-top);
  }
}

@mixin notch-styles-bottom($bottom: false) {
  @if $bottom {
    @supports (padding: max(0px)) {
      padding-bottom: max(#{$bottom}, env(safe-area-inset-bottom));
    }
  } @else {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

/**
 * Enables vertical scrolling and user selection
 * that's prevented by hammerjs
 * Reference: Reference: https://github.com/hammerjs/hammer.js/issues/1014
 */
@mixin hammerSwipeOverrides($scrolling: true, $selection: true) {
  @if $scrolling {
    touch-action: pan-y !important;
  }

  @if $selection {
    @media (min-width: $breakpoint-tablet) {
      user-select: auto !important;
    }
  }
}
/* stylelint-enable */

@mixin generate-backdrop($translucent: true) {
  @if $translucent {
    background: $overlay-dark;

    &[data-color="dark"] {
      background: $overlay-mid;
    }
  }

  @else {
    background: var(--color-bg-light);

    &[data-color="dark"] {
      background: $neutral-xxdark;
    }
  }
}

@mixin sticky-action-buttons() {
  flex: 1 1 auto;

  @media (min-width: $breakpoint-tablet) {
    flex: 0 0 auto;
  }
}

/*
 * We need webkit-scrollbar in a media query like we this to hide the scroll bart only in mobie.
 * scrollbar-width is needed for firefox, since webkit-scrollbar is only for Chrome and Safari.
 */

@mixin hideScrollBarMobile() {
  scrollbar-width: none;

  @media (min-width: $breakpoint-tablet) {
    scrollbar-width: auto;
  }

  &::-webkit-scrollbar {
    @media (max-width: $breakpoint-tablet - 1) {
      display: none;
    }
  }
}

@mixin mobile-gutter() {
  @media (max-width: ($breakpoint-tablet - 1)) {
    padding-left: calc(env(safe-area-inset-left) + #{$spacing-gutter});
    padding-right: calc(env(safe-area-inset-left) + #{$spacing-gutter});
  }
}

/**
 * Only applies :hover on non-touch screen devices.
 *
 * $fallbackToFocus adds the @content to :focus state on
 * touch screens but requires the element to have a tabIndex.
 */
@mixin hover($fallbackToFocus: false) {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }

  @if $fallbackToFocus {
    @media (hover: none) {
      &:focus {
        @content;
      }
    }
  }
}

@mixin scrollbar-style($scroll-thumb, $scrollbar) {
  // Styles only for Firefox
  scrollbar-color: $scroll-thumb $scrollbar;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: $scrollbar;
    border-radius: 10px;
    box-shadow: inset $box-shadow-level-1;
  }

  &::-webkit-scrollbar {
    background-color: $scrollbar;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $scroll-thumb;
    border-radius: 10px;
    box-shadow: inset $box-shadow-level-1;
  }
}

// overflow: clip is not supported in Safari 15.6 and older
// Remove once all of our supported browsers support this functionality
@mixin overflow-clip-when-supported() {
  /* stylelint-disable-next-line freelancer/no-overflow-hidden */
  overflow: hidden;

  // TODO: FIXME - T299607 overflow: clip on Chrome 126.0.6478.61 now acts like visible either
  //@supports (overflow: clip) {
  //  overflow: clip;
  //
  //  // There are some strange case(s) in firefox where "clip" acts like
  //  // "visible" rather than "hidden", reverting to hidden on firefox
  //  @supports (-moz-appearance: none) {
  //    overflow: hidden;
  //  }
  //}
}

@mixin overflow-x-clip-when-supported() {
  overflow-x: hidden;

  @supports (overflow-x: clip) {
    overflow-x: clip;

    @supports (-moz-appearance: none) {
      /* stylelint-disable-next-line freelancer/no-overflow-hidden */
      overflow-x: hidden;
    }
  }
}

@mixin overflow-y-clip-when-supported() {
  overflow-y: hidden;

  @supports (overflow-y: clip) {
    overflow-y: clip;

    @supports (-moz-appearance: none) {
      /* stylelint-disable-next-line freelancer/no-overflow-hidden */
      overflow-y: hidden;
    }
  }
}

/**
 * Animations used for close buttons.
 */
@mixin spin-on-hover {
  transition: transform 0.1s ease-out;

  &:hover,
  &:focus {
    transform: rotate(90deg);
  }
}

@mixin highlight-on-hover($background) {
  transition: background 0.15s ease-in;

  &:hover {
    &::before {
      background-color: $background;
    }
  }
}

@mixin spin-and-fade-on-hover {
  opacity: 0.5;
  transition: transform 0.1s ease-out, opacity 0.1s linear;

  &:hover,
  &:focus {
    opacity: 1;
    transform: rotate(90deg);
  }
}

@mixin spin-and-highlight-on-hover($background) {
  transition: background 0.15s ease-in, transform 0.1s ease-out;

  &:hover,
  &:focus {
    transform: rotate(90deg);

    &::before {
      background-color: $background;
    }
  }
}

//home page animation styles
@mixin animated-line-top {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(25, 110, 157, 1) 40%, rgba(41, 178, 254, 1) 100%);
  border-radius: 6px;
  height: 50px;
  left: 12px;
  position: absolute;
  top: -50px;
  width: 3px;
}

@mixin animated-logo {
  /* stylelint-disable-next-line */
  background-image: url("../../assets/home/home-animated-scroll.png"); //this is need it for the animation
  background-repeat: no-repeat;
  content: "";
  height: 64px;
  left: -12px;
  position: absolute;
  top: 0;
  width: 60px;
}

@mixin animated-line-bottom($height: 84px) {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(25, 110, 157, 1) 40%, rgba(41, 178, 254, 1) 100%);
  border-radius: 6px;
  height: calc(100% - #{$height});
  left: 12px;
  position: absolute;
  top: 64px;
  width: 3px;
}

/*
  Custom AI Border animation. To be applied in the `before` pseudoelement of the parent element
*/
@mixin ai-border($left, $top, $width, $height) {
  animation: rotate 3s linear infinite;
  background: linear-gradient(
    60deg,
    $blue-dark,
    $blue-xdark,
    $pink,
    $pink-dark,
  );
  background-color: $pink;
  border-radius: $border-radius;
  content: "";
  height: $height;
  left: $left;
  position: absolute;
  top: $top;
  width: $width;
  z-index: -2;

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
}

@mixin flex-direction-generator($size: "") {
  @if $size != "" {
    $size: "-" + $size;
  }

  #{"&[data-flex-direction" + $size + "=column]"} {
    flex-direction: column;
  }

  #{"&[data-flex-direction" + $size + "=row]"} {
    flex-direction: row;
  }
}

@mixin flex-align-generator($size: "") {
  @if $size != "" {
    $size: "-" + $size;
  }

  #{"&[data-flex-align" + $size + "=center]"} {
    align-items: center;
  }

  #{"&[data-flex-align" + $size + "=flex-start]"} {
    align-items: flex-start;
  }

  #{"&[data-flex-align" + $size + "=flex-end]"} {
    align-items: flex-end;
  }

  #{"&[data-flex-align" + $size + "=baseline]"} {
    align-items: baseline;
  }

  #{"&[data-flex-align" + $size + "=stretch]"} {
    align-items: stretch;
  }
}

@mixin flex-justify-generator($size: "") {
  @if $size != "" {
    $size: "-" + $size;
  }

  #{"&[data-flex-justify" + $size + "=center]"} {
    justify-content: center;
  }

  #{"&[data-flex-justify" + $size + "=flex-start]"} {
    justify-content: flex-start;
  }

  #{"&[data-flex-justify" + $size + "=flex-end]"} {
    justify-content: flex-end;
  }

  #{"&[data-flex-justify" + $size + "=space-between]"} {
    justify-content: space-between;
  }

  #{"&[data-flex-justify" + $size + "=space-around]"} {
    justify-content: space-around;
  }

  #{"&[data-flex-justify" + $size + "=space-evenly]"} {
    justify-content: space-evenly;
  }
}
