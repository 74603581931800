@use "settings/settings" as *;

// Nest selector inside image-cropper to avoid any leaks
// Use !important to override .cropper and .square's specificty
/* stylelint-disable */
image-cropper {
  .cropper {
    color: $blue-dark !important;
    outline-color: rgba($neutral-xxdark, 0.6) !important;
  }

  .square {
    background: currentColor !important;
  }
}
/* stylelint-enable */
