/* stylelint-disable color-no-hex */
$blue-xlight: #eaf2fd;
$blue-light: #acccf8;
$blue: #589af0;
$blue-dark: #1772eb;
$blue-xdark: #115cc0;
$blue-xxdark: #082e60;

$orange-xxxlight: #fffad4;
$orange-xxlight: #ffeba8;
$orange-xlight: #ffc24e;
$orange-light: #ffa32d;
$orange: #fc8c14;
$orange-dark: #f77d0e;
$orange-xdark: #f57207;
$orange-xxdark: #e96800;
$orange-xxxdark: #ad4d00;
$orange-preferred-freelancer: #ff6700; //Design team wil provide a new scale of orange color.

$neutral-xxxlight: #fff;
$neutral-xxlight: #fcfcfd;
$neutral-xlight: #eef0f4;
$neutral-light: #bcc5d3;
$neutral: #5c6f8c;
$neutral-dark: #3d4a5d;
$neutral-xdark: #232a35;
$neutral-xxdark: #12151b;

$green-xlight: #defee9;
$green-light: #9bfbbc;
$green: #07bc44;
$green-dark: #058430;
$green-xdark: #023815;

$red-xxxxlight: #fff6f5;
$red-xxxlight: #ffcdc7;
$red-xxlight: #ffb7a3;
$red-xlight: #ff624d;
$red-light: #f84438;
$red: #eb3730;
$red-dark: #e02a28;
$red-xdark: #d31721;
$red-xxdark: #c20014;
$red-xxxdark: #891b21;

$purple-light: #e5d3fd;
$purple: #7f21f7;
$purple-dark: #3d0584;

$yellow-light: #fff3cb;
$yellow: #ffd747;
$yellow-dark: #e1b000;

$aqua: #1eb5bd;

$pink-light: #ffdaed;
$pink: #e60278;
$pink-dark: #860146;

$brand-apple: #000;
$brand-facebook: #1877f2;
$brand-twitter: #00b6f1;
$brand-google: #df4a32;
$brand-youtube: #f00;
$brand-instagram: #c32aa3;
$brand-linkedin: #0a66c2;
$brand-pinterest: #e60019;
$brand-freelancer: #29b2fe;

$black: #000; //ask UI-ENG before to use this color
/* stylelint-enable color-no-hex */
