/**
  If you add anything here, add it to @freelancer/ui/breakpoints.ts as well
*/
$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop-small: 960px;
$breakpoint-desktop-large: 1200px;
$breakpoint-desktop-xlarge: 1440px;
$breakpoint-desktop-xxlarge: 1600px;
$breakpoint-desktop-xxxlarge: 1920px;
$breakpoint-desktop-xxxxlarge: 2560px;
