@use "quill/dist/quill.snow.css";
@use "settings/settings" as *;

/* stylelint-disable selector-class-pattern, selector-max-type, color-no-hex */
// overrides the style in "node_modules/quill/dist/quill.snow.css",
.ql-container {
  font-family: $font-family-body;
  font-size: $font-size-ui;
}

.ql-editor {
  // allow the editor to be scrollable
  max-height: inherit;
  min-height: inherit;
  overflow: auto;
  resize: inherit;

  p:not(:last-of-type) {
    // Corresponds to the margin-bottom of paragraph element in html-renderer
    margin-bottom: $spacing-mid;
  }

  &.ql-blank::before {
    color: var(--color-placeholder-text);
  }
}

.ql-tooltip {
  z-index: 1;
}

.ql-toolbar.ql-snow {
  border-bottom: 0;
}

.ql-toolbar {
  .ql-snow,
  .ql-stroke {
    stroke: var(--color-foreground);
  }
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #ccc; // re-adds top border to text editor, required on toolbar removal
}

.ql-mention-list-container {
  background-color: var(--color-bg-light);
  box-shadow: $box-shadow-level-2;
  overflow: auto;
  z-index: 1;
}

.ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;

  @include overflow-clip-when-supported();
}

.ql-mention-list-item {
  cursor: pointer;
  padding: $spacing-xxsmall $spacing-xsmall;

  &:hover {
    background-color: $neutral-xxlight;
  }
}

.ql-mention-list-item.selected {
  box-shadow: inset 0 0 0 2px $field-focus-color;
}

.PJPEntropyContainer {
  /* Add your custom styles for .ql-editor here */
  .ql-editor {
    @include scrollbar-style(var(--color-border-light), var(--color-bg-mid));
    @include text-size-generator();
    @include text-weight-generator();
    color: var(--color-foreground);
    user-select: auto;
  }

  .ql-editor .fade-highlight {
    animation: fadeInOut 800ms ease-out;
  }

  .ql-editor p {
    margin-bottom: $spacing-none;
  }
}

@keyframes fadeInOut {
  0% {
    background: rgba(34, 116, 201, 0);
  }

  1% {
    background: rgba(34, 116, 201, 1);
  }

  100% {
    background: transparent;
  }
}
/* stylelint-enable */
