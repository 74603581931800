@use "settings/settings" as *;
@tailwind base;
@tailwind components;
@tailwind utilities;

/* stylelint-disable selector-max-type */
p {
  color: var(--color-foreground);
  font-size: var(--text-size-ui);
  font-weight: $font-weight-normal;
  line-height: var(--line-height-ui);
}
/* stylelint-enable selector-max-type */
