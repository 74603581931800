/**
 * This file is used to undo all of the global styling that GAF imposes to
 * preserve the global styling used in the webapp. The contents are just
 * basically resetting all styling.
 */
// stylelint-disable selector-max-type
hr {
  border-bottom: 0;
  border-top: 0;
  margin: 0.5em 0;
  width: auto;
}

a {
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
